import { render, staticRenderFns } from "./PlatformTable.vue?vue&type=template&id=434fe493&scoped=true&"
import script from "./PlatformTable.vue?vue&type=script&lang=js&"
export * from "./PlatformTable.vue?vue&type=script&lang=js&"
import style0 from "./PlatformTable.vue?vue&type=style&index=0&id=434fe493&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434fe493",
  null
  
)

export default component.exports